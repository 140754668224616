import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/Hero.css'; // Ensure this path is correct

const HeroComponent = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="hero-section">
      <div className="hero-overlay" data-aos="fade-in">
        <div className="hero-card" data-aos="fade-down">
          <h1>SAP Training Tech. in Nagpur</h1>
          <p>
            Welcome to the best SAP training in Nagpur. Our classes offer
            comprehensive learning experiences, equipping you with the skills
            needed to excel in SAP technologies in online & classroom mode.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
