import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Css/OverlayCard.css'; // Make sure to create this CSS file
import C1 from '../assets/1.webp';
import C2 from '../assets/2.webp';
import C3 from '../assets/3.webp';
import C4 from '../assets/4.webp';

const cardData = [
  { id: 1, image: C1, heading: 'Comprehensive Curriculum', content: 'Get a comprehensive curriculum covering key tools like continuous integration, delivery, and infrastructure as code in our SAP Training Tech.' },
  { id: 2, image: C2, heading: 'Hands-on Learning', content: 'Gain practical skills with real-world projects and simulations in our SAP Training Tech., boosting your understanding of industry practices.' },
  { id: 3, image: C3, heading: 'Expert Guidance', content: 'Gain insights and personalized guidance from seasoned SAP experts.' },
  { id: 4, image: C4, heading: 'Job-ready Skills', content: 'Get job-ready skills, certifications, and 100% placement assistance for a confident start to your SAP career.' },
];

const ImageOverlayCards = () => {
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
      easing: 'ease-in-out',
      once: false, // Ensure animations trigger every time elements enter the viewport
      mirror: true, // Trigger animations both on scroll down and scroll up
    });
  }, []);

  const handleMouseEnter = (id) => {
    setActiveCard(id);
  };

  const handleMouseLeave = () => {
    setActiveCard(null);
  };

  return (
    <div className="image-overlay-container">
      {cardData.map((card, index) => (
        <div
          key={card.id}
          className="image-card"
          onMouseEnter={() => handleMouseEnter(card.id)}
          onMouseLeave={handleMouseLeave}
          data-aos-delay={index * 300}
        >
          <div className={`image-wrapper ${activeCard === card.id ? 'active' : ''}`}>
            <img src={card.image} alt={`Card ${card.id}`} />
            <div className="image-heading">
              <h3>{card.heading}</h3>
            </div>
            {activeCard === card.id && (
              <div className="card-overlay">
                <p>{card.content}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageOverlayCards;
