import React, { useState, useEffect } from "react";
import "../Css/Navbar.css";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="logo-name"><a href="/"><span>SAP</span> Training Tech.</a></div>
      <button className="toggle-button" onClick={toggleMenu}>
        ☰  
      </button>
      <ul className={`nav-links ${isActive ? "active" : ""}`}>
        <li><a href="/">Home</a></li>
        <li><a href="/sapcourse">SAP Course</a></li>
        <li>
          <a href="#modules">Modules</a>
          <ul id="li" className="dropdown-menu">
            <li><a href="/sapmm">SAP MM</a></li>
            <li><a href="/sapfico">SAP FICO</a></li>
            <li><a href="/sapabap">SAP ABAP</a></li>
            <li><a href="/saphcm">SAP HCM</a></li>
            <li><a href="/sapscm">SAP SCM</a></li>
            <li><a href="/sapariba">SAP ARIBA</a></li>
            <li><a href="/sappp">SAP PP</a></li>
            <li><a href="/sapsd">SAP SD</a></li>
            <li><a href="/sapbasis">SAP BASIS</a></li>
            <li><a href="/sapfiori">SAP Fiori</a></li>
            <li><a href="/sapqm">SAP QM</a></li>
            <li><a href="/sapwmewm">SAP WM & EWM</a></li>
            <li><a href="/sappm">SAP PM</a></li>
            <li><a href="/saplesl">SAP LE & SL</a></li>
          </ul>
        </li>
        <li><a href="/about">About Us</a></li>
        <li><a href="https://sapalogypvtltd.wordpress.com/">Blog</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
