import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sappm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Enroll in Our SAP PM Program in Nagpur</h2>
        <p>
          Discover the comprehensive SAP PM program in Nagpur tailored for professionals eager to excel in materials management and procurement. Our expertly designed curriculum offers hands-on training and practical experience, essential for mastering SAP PM effectively.
        </p>
        <p>
          Benefit from a structured course that not only provides a deep understanding of SAP PM but also equips you with industry-recognized certification. Our accelerated program ensures you gain essential skills and knowledge in just 1.5 months, setting you on a path to career advancement.
        </p>
        <h3>Our program covers a broad range of topics, including:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Tailored resume-building workshops to enhance your profile</li>
          <li><FaClipboardCheck className="benefit-icon" /> Personalized interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Practical six-month internship experience</li>
          <li><FaBriefcase className="benefit-icon" /> Job placement assistance with a focus on career growth</li>
          <li><FaBuilding className="benefit-icon" /> Access to cutting-edge SAP PM tools and software</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-world project execution for hands-on learning</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>Role of an SAP PM Professional</h2>
        <ul className="role-list">
          <li>Customize SAP PM modules to align with specific business needs and processes.</li>
          <li>Oversee procurement activities and manage inventory levels with precision.</li>  
          <li>Maintain data integrity and perform routine data management tasks.</li>
          <li>Develop insightful reports and dashboards to support strategic decisions.</li>
          <li>Engage with stakeholders, offer training, and enhance SAP PM functionalities for operational efficiency.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Your Roadmap to Mastering SAP PM</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP PM</h3>
            <p>Grasp the fundamental concepts of SAP PM and its significance in the broader SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP PM Configuration</h3>
            <p>Learn to tailor SAP PM modules to cater to diverse business requirements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Inventory and Procurement</h3>
            <p>Gain mastery in advanced inventory management and procurement strategies.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Data Management Mastery</h3>
            <p>Become proficient in managing data quality, reporting, and analytics in SAP PM.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Process Automation in SAP PM</h3>
            <p>Explore automation tools to enhance business processes within SAP PM.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Expert-Level Topics</h3>
            <p>Dive deep into complex topics such as SAP PM integrations and custom developments.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Course Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: SAP PM Overview</h3>
            <p>Introduction to SAP PM, its core modules, and their role within the SAP framework.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: Master Data Management</h3>
            <p>In-depth look at SAP PM master data, including material and vendor management.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Procurement Strategies</h3>
            <p>Detailed insights into the procurement process, including requisitions and purchase orders.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Effective Inventory Management</h3>
            <p>Managing inventory, including goods receipts, issues, and stock transfers within SAP PM.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Invoice Verification and Integration</h3>
            <p>Procedures for invoice verification and its integration with financial modules.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Reporting and Analytics</h3>
            <p>Creating and analyzing reports to support business decisions in SAP PM.</p>
          </div>
        </div>
      </section>
{/* why learn section */}
<section className="why-learn-sapmm">
  <h2>Why You Should Learn SAP PM</h2>
  <div className="why-learn-sapmm-items">
    <div className="why-learn-sapmm-item">
      <h3>Lucrative Career Opportunities</h3>
      <p>SAP PM skills are highly sought after, with a growing demand for professionals proficient in managing plant maintenance and asset management processes. This translates into excellent job opportunities, competitive salaries, and significant career advancement prospects in various industries.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Industry Relevance</h3>
      <p>SAP PM is a core module in SAP's ERP suite, widely used by organizations globally to streamline their plant maintenance and asset management processes. Mastering SAP PM ensures you’re equipped with a skill set relevant to numerous industries, including manufacturing, utilities, and engineering.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Versatility and Customization</h3>
      <p>SAP PM offers extensive customization options to tailor the system to specific business needs. This versatility allows professionals to adapt SAP PM solutions for diverse roles, from maintenance planners to asset managers, enhancing its applicability across different business functions.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Continuous Learning and Development</h3>
      <p>Learning SAP PM provides access to continuous professional development through certifications, training programs, and a supportive global community. The platform’s regular updates and advancements offer ongoing opportunities to expand your knowledge and stay current with industry trends and best practices.</p>
    </div>
  </div>
</section>
 
{/* certification section */}
<section className="certification-section">
  <div className="certification-content">
    <div className="certification-text">
      <h2>SAP PM Certification</h2>
      <p>
        Certification from SAP PM Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP PM.
      </p>
      <p>
        The SAP PM certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP PM certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - Plant Maintenance with SAP S/4HANA.
      </p>
    </div>
    <div className="certification-image">
      <img src={CertificateImage} alt="SAP PM Certification" className="certification-img" />
    </div>
  </div>
</section>

{/* key-insights section */}

<section className="key-insights-section">
  <div className="key-insights-content">
    <div className="key-insights-text">
      <h2>Key Insights</h2>
      <div className="key-insights-list">
        <ul>
          <li><strong>Key Highlights:</strong></li>
          <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
          <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
          <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
          <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
          <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
          <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
          <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
          <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
          <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
          <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
        </ul>
      </div>
    </div>
  </div>
</section>

{/* alumni section */}
<section className="alumni-section">
  <h2>Our Alumni Works At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Works At" />
  </div>
</section>
      {/* Key Insights Section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Program Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Program Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Extensive 53+ Hours of Expert-Led Training</li>
                <li><FaCheckCircle className="benefit-icon" /> Guidance for Certification Success</li>
                <li><FaCheckCircle className="benefit-icon" /> Personalized Mentorship Throughout the Course</li>
                <li><FaCheckCircle className="benefit-icon" /> Real-World Projects for Practical Experience</li>
                <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Options with Expert Support</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

        {/* alumni section */}
        <section className="alumni-section">
        <h2>Our Alumni Works At</h2>
        <div className="alumni-image">
          <img src={PlacementImage} alt="Our Alumni Works At" />
        </div>
      </section>

<MySlideshow/>

{/* FAQ Section */}
<section className="faq-section">
  <h2>Frequently Asked Questions</h2>
  <div className="faq">
    <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
      <div className="faq-question">What is SAP PM?</div>
      <div className="faq-answer">SAP PM (Plant Maintenance) is a module in SAP ERP Central Component (ECC) that provides companies with plant maintenance, asset management, and service management capabilities.</div>
    </div>
    <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
      <div className="faq-question">What are the prerequisites for learning SAP PM?</div>
      <div className="faq-answer">Basic knowledge of business processes and familiarity with SAP's general concepts can be helpful, but there are no strict prerequisites for learning SAP PM.</div>
    </div>
    <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
      <div className="faq-question">What career opportunities are available after completing SAP PM training?</div>
      <div className="faq-answer">Career opportunities include roles like SAP PM Consultant, SAP PM Analyst, SAP PM End-User, and various plant maintenance and asset management positions.</div>
    </div>
    <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
      <div className="faq-question">How does SAP PM integrate with other SAP modules?</div>
      <div className="faq-answer">SAP PM integrates with modules like SAP MM (Materials Management), SAP SD (Sales and Distribution), SAP QM (Quality Management), and SAP FICO (Finance and Controlling) to streamline various business processes.</div>
    </div>
  </div>
</section>

    </div>
  );
};

export default Sappm;
