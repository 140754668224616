import React, { useState, useEffect } from 'react';
import '../Css/SplashScreen.css'; // Assuming you place your styles here

function SplashScreen() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000); // Total time for splash animations + buffer
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showSplash ? (
        <div id="splash-screen">
          <div className="splash-text" id="text1">SAP</div>
          <div className="splash-text" id="text2">Training</div>
          <div className="splash-text" id="text3">Tech.</div>
        </div>
      ) : (
        <div id="main-content">
          <h1>Welcome to My Website</h1>
          <p>This is the main content of the website.</p>
        </div>
      )}
    </div>
  );
}

export default SplashScreen;
