import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const SapAriba = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP Ariba Classes in Nagpur</h2>
        <p>
          Join SAP Ariba classes in Nagpur for the best SAP Ariba training, designed for both freshers and experienced professionals. With expert guidance and guaranteed placement assistance, our course covers all technical topics in detail, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
          Our job-oriented program not only awards you a certificate but also imparts knowledge equivalent to over 10 years of field experience. We respect your time and strive to complete the SAP Ariba training in just 1.5 months. Enroll with us to advance your career with practical skills and valuable certification.
        </p>
        <h3>Every topic will be thoroughly explored, including:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP Ariba software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP Ariba professional do?</h2>
        <ul className="role-list">
          <li>Configure and customize SAP Ariba modules to meet business requirements.</li>
          <li>Manage procurement processes and supplier relationships efficiently.</li>  
          <li>Handle data management tasks and ensure data accuracy.</li>
          <li>Create reports and dashboards for insights and decision-making.</li>
          <li>Collaborate with stakeholders, provide training and support, and optimize SAP Ariba for business success.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP Ariba</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP Ariba</h3>
            <p>Understand the basics of SAP Ariba and its role in the SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP Ariba Configuration</h3>
            <p>Learn to configure SAP Ariba modules to meet business requirements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Procurement and Sourcing</h3>
            <p>Master procurement management, sourcing processes, and supplier management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Data Management</h3>
            <p>Handle data management, quality, and reporting within SAP Ariba.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Automation in SAP Ariba</h3>
            <p>Explore automation options and streamline business processes in SAP Ariba.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as integration with other SAP modules and custom developments.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP Ariba</h3>
            <p>Overview of SAP Ariba, its modules, and how it integrates with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: SAP Ariba Master Data</h3>
            <p>Understanding master data in SAP Ariba, including supplier and procurement master data.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Procurement and Sourcing Process</h3>
            <p>Detailed exploration of the procurement and sourcing process, including purchase requisitions, purchase orders, and supplier management.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Data Management</h3>
            <p>Managing data in SAP Ariba, including data quality, reporting, and analytics.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Invoice Management</h3>
            <p>Process of invoice management and integration with the financial module.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Reporting and Analytics</h3>
            <p>Creating reports and analyzing data within SAP Ariba to support business decisions.</p>
          </div>
        </div>
      </section>

      {/* why learn section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP Ariba</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP Ariba skills are highly sought after, with a growing demand for professionals proficient in managing procurement and sourcing processes. This translates into excellent job opportunities, competitive salaries, and significant career advancement prospects in various industries.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP Ariba is a core module in SAP's procurement suite, widely used by organizations globally to streamline their procurement, sourcing, and supplier management processes. Mastering SAP Ariba ensures you’re equipped with a skill set relevant to numerous industries, including manufacturing, retail, and logistics.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP Ariba offers extensive customization options to tailor the system to specific business needs. This versatility allows professionals to adapt SAP Ariba solutions for diverse roles, from procurement specialists to supply chain managers, enhancing its applicability across different business functions.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>Learning SAP Ariba provides access to continuous professional development through certifications, training programs, and a supportive global community. The platform’s regular updates and advancements offer ongoing opportunities to expand your knowledge and stay current with industry trends and best practices.</p>
          </div>
        </div>
      </section>

      {/* Certification Section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP Ariba Certification</h2>
            <p>
              Certification from SAP Ariba Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP Ariba.
            </p>
            <p>
              The SAP Ariba certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP Ariba certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - Procurement with SAP S/4HANA.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP Ariba Certification" className="certification-img" />
          </div>
        </div>
      </section>

      {/* Key Insights Section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
                <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
                <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
                <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
                <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
                <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Alumni Section */}
      <section className="alumni-section">
        <h2>Our Alumni Works At</h2>
        <div className="alumni-image">
          <img src={PlacementImage} alt="Our Alumni Works At" />
        </div>
      </section>

      {/* Slideshow Component */}
      <MySlideshow />

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP Ariba?</div>
            <div className="faq-answer">SAP Ariba is a cloud-based procurement and supply chain management solution that helps organizations streamline procurement processes, manage suppliers, and optimize spending.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">What are the prerequisites for learning SAP Ariba?</div>
            <div className="faq-answer">Basic knowledge of business processes and familiarity with cloud-based procurement solutions can be helpful, but there are no strict prerequisites for learning SAP Ariba.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">What career opportunities are available after completing SAP Ariba training?</div>
            <div className="faq-answer">Career opportunities include roles like SAP Ariba Consultant, SAP Ariba Analyst, SAP Ariba End-User, and various procurement and supply chain management positions.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">How does SAP Ariba integrate with other SAP modules?</div>
            <div className="faq-answer">SAP Ariba integrates with modules like SAP SD (Sales and Distribution), SAP MM (Materials Management), SAP PP (Production Planning), and SAP FICO (Finance and Controlling) to streamline various business processes.</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SapAriba;
