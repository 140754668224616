import React, { useEffect } from 'react';
import '../Css/About.css'; // Importing CSS for styling
import { FaLaptopCode, FaConciergeBell, FaTrophy } from 'react-icons/fa'; // Importing icons from react-icons
import { FaUserGraduate, FaClock, FaBriefcase } from 'react-icons/fa';
import AboutImage from '../assets/about.webp'; 
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration for the animations
  }, []);

  return (
    <div className="about-container">
      <section className="image-section">
        <img src={AboutImage} alt="About Us" className="about-image" />
        <div className="overlay-text">
          <h1>About Us</h1>
          <p>We are experienced Sap Training team</p>
        </div>
      </section>
      <section className="info-section">
        <div className="info-item">
          <FaUserGraduate className="info-icon" />
          <p>Qualified Trainers</p>
        </div>
        <div className="info-item">
          <FaClock className="info-icon" />
          <p>Over 9 Years Experience</p>
        </div>
        <div className="info-item">
          <FaBriefcase className="info-icon" />
          <p>Best Placements</p>
        </div>
      </section>
      <section className="card-section">
        <div className="card">
          <FaLaptopCode className="card-icon" />
          <h3>Excel with Internships</h3>
          <p>Accelerate career growth through hands-on learning and mentorship in our exceptional internship programs.</p>
        </div>
        <div className="card">
          <FaConciergeBell className="card-icon" />
          <h3>Free Consultation</h3>
          <p>Get personalized guidance and answers to all your questions with our free consultation service.</p>
        </div>
        <div className="card">
          <FaTrophy className="card-icon" />
          <h3>Proven Placements</h3>
          <p>Achieve success with our track record of delivering outstanding placements.</p>
        </div>
      </section>
    </div>
  );
};

export default About;
