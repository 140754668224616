import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home'
import About from './Pages/About'
import Contact from './Pages/Contact'
import SAPCOURSE from './Pages/Sapcourse'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SAPABAP from './Pages/modules/Sapabap'
import SAPARIBA from './Pages/modules/Sapariba'
import SAPBASIS from './Pages/modules/Sapbasis'
import SAPFICO from './Pages/modules/Sapfico'
import SAPFIORI from './Pages/modules/Sapfiori'
import SAPHCM from './Pages/modules/Saphcm'
import SAPLESL from './Pages/modules/Saplesl'
import SAPMM from './Pages/modules/Sapmm'
import SAPPM from './Pages/modules/Sappm'
import SAPPP from './Pages/modules/Sappp'
import SAPQM from './Pages/modules/Sapqm'
import SAPSCM from './Pages/modules/Sapscm'
import SAPSD from './Pages/modules/Sapsd'
import SAPWMEWM from './Pages/modules/Sapwmewm'
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sapcourse" element={<SAPCOURSE />} />
        <Route path="/sapabap" element={<SAPABAP />} />
        <Route path="/sapariba" element={<SAPARIBA />} />
        <Route path="/sapbasis" element={<SAPBASIS />} />
        <Route path="/sapfico" element={<SAPFICO />} />
        <Route path="/sapfiori" element={<SAPFIORI />} />
        <Route path="/saphcm" element={<SAPHCM />} />
        <Route path="/saplesl" element={<SAPLESL />} />
        <Route path="/sapmm" element={<SAPMM />} />
        <Route path="/sappm" element={<SAPPM />} />
        <Route path="/sappp" element={<SAPPP />} />
        <Route path="/sapqm" element={<SAPQM />} />
        <Route path="/sapscm" element={<SAPSCM />} />
        <Route path="/sapsd" element={<SAPSD />} />
        <Route path="/sapwmewm" element={<SAPWMEWM />} />
      </Routes>
      <Footer/>
    
    </Router>
  );
}

export default App;
