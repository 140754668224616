import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP HCM Classes in Nagpur</h2>
        <p>
          Enroll in our SAP HCM classes in Nagpur, designed to provide you with expert training in SAP Human Capital Management. Whether you're a fresher or an experienced professional, our course offers in-depth coverage of all technical aspects, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
          With a job-oriented program, certification, and practical knowledge equivalent to years of experience, our SAP HCM training is tailored to help you achieve your career goals efficiently.
        </p>
        <h3>Our comprehensive training includes:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP HCM software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP HCM professional do?</h2>
        <ul className="role-list">
          <li>Administer and manage SAP HCM modules and configurations.</li>
          <li>Handle personnel administration, organizational management, and payroll processing.</li>  
          <li>Manage employee data, recruitment, and performance management.</li>
          <li>Monitor system performance and troubleshoot technical issues related to HR functions.</li>
          <li>Collaborate with HR teams to ensure smooth SAP HCM operations and support.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP HCM</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP HCM</h3>
            <p>Understand the basics of SAP HCM and its role in human resource management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP HCM Configuration</h3>
            <p>Learn to configure SAP HCM modules to meet business needs.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Personnel Administration</h3>
            <p>Master tasks related to employee data management and organizational structures.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Payroll Management</h3>
            <p>Manage payroll processing, including salary calculations and tax management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Recruitment and Performance Management</h3>
            <p>Explore modules for recruitment, performance appraisals, and employee development.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as SAP HCM integration with other modules and future trends.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP HCM</h3>
            <p>Overview of SAP HCM and its integration with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: Personnel Administration</h3>
            <p>Understanding personnel administration processes, including employee data management and organizational structures.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Payroll Management</h3>
            <p>Managing payroll processing, salary calculations, and compliance.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Recruitment and Performance Management</h3>
            <p>Creating and managing recruitment processes and performance appraisals.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Integration with SAP Modules</h3>
            <p>Integrating SAP HCM with other SAP modules and IT systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Advanced Topics</h3>
            <p>Exploring advanced topics such as SAP HCM optimization and future trends in human resource management.</p>
          </div>
        </div>
      </section>

      {/* why learn section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP HCM</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP HCM skills are in high demand across industries, leading to excellent job opportunities and career growth in HR management and IT support.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP HCM is a core module for HR management used globally, making it an essential skill for HR professionals.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP HCM offers extensive customization, allowing professionals to tailor HR processes to various business needs and organizational structures.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>With regular updates and certifications, SAP HCM provides continuous learning opportunities to keep up with industry trends and advancements in HR technology.</p>
          </div>
        </div>
      </section>
 
      {/* certification section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP HCM Certification</h2>
            <p>
              Our SAP HCM Certification in Nagpur validates your expertise in SAP human resource management. The certification is highly regarded and enhances your competitiveness in the job market.
            </p>
            <p>
              We prepare you comprehensively for global SAP HCM certifications, ensuring a high success rate in exams like SAP Certified Application Associate - Human Capital Management with SAP ERP.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP HCM Certification" className="certification-img" />
          </div>
        </div>
      </section>

      {/* key-insights section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Access to SAP HCM System for Practical Experience</li>
                <li><FaCheckCircle className="benefit-icon" /> Career Guidance and Placement Support</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
{/* alumni section */}
<section className="alumni-section">
  <h2>Our Alumni Works At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Works At" />
  </div>
</section>

<MySlideshow/>

      {/* FAQ Section */}
      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">  What is SAP HCM and why is it important?</div>
            <div className="faq-answer">SAP HCM (Human Capital Management) is a module within the SAP ERP suite that handles various HR functions such as personnel administration, payroll, recruitment, and performance management. It is essential for managing employee data and streamlining HR processes in large organizations.
            </div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question"> What are the prerequisites for enrolling in the SAP HCM course?</div>
            <div className="faq-answer">There are no strict prerequisites for enrolling in the SAP HCM course. Basic knowledge of HR concepts and some familiarity with SAP systems is beneficial but not mandatory.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">How long is the SAP HCM training program?</div>
            <div className="faq-answer">The SAP HCM training program typically spans 8 to 12 weeks, depending on the course format and schedule.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">Will I receive a certification upon completion of the course?</div>
            <div className="faq-answer">Yes, upon successful completion of the SAP HCM course, you will receive a certification that validates your skills and knowledge in SAP HCM.</div>
          </div>
        </div>
      </section>
  
    </div>
  );
};

export default Sapmm;


