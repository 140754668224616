import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapabap = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP ABAP Classes in Nagpur</h2>
        <p>
          Join SAP ABAP classes in Nagpur for the best SAP ABAP training, designed for both freshers and experienced professionals. With expert guidance and guaranteed placement assistance, our course covers all technical topics in detail, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
          Our job-oriented program not only awards you a certificate but also imparts knowledge equivalent to over 10 years of field experience. We respect your time and strive to complete the SAP ABAP training in just 1.5 months. Enroll with us to advance your career with practical skills and valuable certification.
        </p>
        <h3>Every topic will be thoroughly explored, including:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP ABAP development tools for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP ABAP professional do?</h2>
        <ul className="role-list">
          <li>Develop and customize SAP applications using ABAP programming language.</li>
          <li>Design and implement complex business processes and solutions.</li>  
          <li>Handle data manipulation and integration tasks.</li>
          <li>Create and manage reports, interfaces, and enhancements.</li>
          <li>Collaborate with functional consultants and technical teams to meet business requirements.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP ABAP</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP ABAP</h3>
            <p>Understand the basics of SAP ABAP and its role in the SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP ABAP Programming Basics</h3>
            <p>Learn ABAP syntax, data types, and control structures.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced ABAP Techniques</h3>
            <p>Master advanced ABAP topics such as ALV Reports, BAPIs, and enhancements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Data Management in ABAP</h3>
            <p>Handle data manipulation, database interactions, and performance optimization.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Integration and Interfaces</h3>
            <p>Explore integration with other SAP modules and external systems.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Debugging and Performance Tuning</h3>
            <p>Learn techniques for debugging ABAP programs and optimizing performance.</p>
          </div>
        </div>
      </section>

      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP ABAP</h3>
            <p>Overview of SAP ABAP, its modules, and how it integrates with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: ABAP Programming Basics</h3>
            <p>Understanding ABAP syntax, variables, and control statements.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Data Dictionary and Internal Tables</h3>
            <p>Working with data dictionary objects and internal tables in ABAP.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Reports and Interfaces</h3>
            <p>Creating interactive reports, forms, and interfaces in SAP ABAP.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Enhancements and Modifications</h3>
            <p>Customizing standard SAP functionality through user exits and BAdIs.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Debugging and Performance Optimization</h3>
            <p>Techniques for debugging ABAP code and optimizing performance.</p>
          </div>
        </div>
      </section>

      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP ABAP</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP ABAP skills are in high demand, with ample job opportunities and competitive salaries. As a core programming language for SAP, ABAP expertise opens doors to numerous roles in various industries.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP ABAP is essential for customizing and developing SAP applications, making it highly relevant to organizations using SAP ERP systems across industries.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>ABAP's flexibility allows for extensive customization of SAP applications, providing professionals the ability to tailor solutions to specific business needs.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>ABAP offers continuous learning opportunities through certifications and updates, ensuring professionals stay current with evolving technology and best practices.</p>
          </div>
        </div>
      </section>

      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP ABAP Certification</h2>
            <p>
              Certification from SAP ABAP Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP ABAP. 
            </p>
            <p>
              The SAP ABAP certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP ABAP certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - ABAP with SAP S/4HANA.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP ABAP Certification" className="certification-img" />
          </div>
        </div>
      </section>

     {/* Key Insights Section */}
     <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
                <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
                <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
                <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
                <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
                <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Alumni Section */}
      <section className="alumni-section">
        <h2>Our Alumni Works At</h2>
        <div className="alumni-image">
          <img src={PlacementImage} alt="Our Alumni Works At" />
        </div>
      </section>

      {/* Slideshow Component */}
      <MySlideshow />

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP ABAP?</div>
            <div className="faq-answer">SAP ABAP (Advanced Business Application Programming) is a programming language used in SAP for developing applications, customizing existing solutions, and creating reports within the SAP environment.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">What are the prerequisites for learning SAP ABAP?</div>
            <div className="faq-answer">Basic programming knowledge and familiarity with SAP’s architecture can be helpful, but there are no strict prerequisites for learning SAP ABAP.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">What career opportunities are available after completing SAP ABAP training?</div>
            <div className="faq-answer">Career opportunities include roles such as SAP ABAP Developer, SAP Consultant, SAP Technical Analyst, and positions in various IT and software development domains.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">How does SAP ABAP integrate with other SAP modules?</div>
            <div className="faq-answer">SAP ABAP integrates with modules like SAP SD (Sales and Distribution), SAP MM (Materials Management), SAP PP (Production Planning), and SAP FICO (Finance and Controlling) to customize and extend functionality across the SAP system.</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sapabap;
