import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad,FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP FICO Classes in Nagpur</h2>
        <p>
        Enroll in our SAP FICO classes in Nagpur, designed to provide you with expert training in financial accounting and controlling. Whether you're a fresher or an experienced professional, our course offers in-depth coverage of all technical aspects, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
        With a job-oriented program, certification, and practical knowledge equivalent to years of experience, our SAP FICO training is tailored to help you achieve your career goals efficiently.
        </p>
        <h3>Our comprehensive training includes:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP MM software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP FICO professional do?</h2>
        <ul className="role-list">
          <li>Configure and customize SAP FICO modules to meet business requirements.</li>
          <li>Manage financial accounting, reporting, and controlling processes.</li>  
          <li>Handle data management tasks and ensure data accuracy in financial reports.</li>
          <li>Create reports and dashboards for insights and decision-making.</li>
          <li>Collaborate with stakeholders, provide training and support, and optimize SAP FICO for business success.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP FICO</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP MM</h3>
            <p>Understand the basics of SAP FICO and its role in financial management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP FICO Configuration</h3>
            <p>Learn to configure SAP FICO modules to meet business financial requirements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Financial Accounting</h3>
            <p>Master financial accounting processes including general ledger, accounts payable, and accounts receivable.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Controlling and Reporting</h3>
            <p>Manage cost centers, profit centers, and create detailed financial reports.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Integration with Other Modules</h3>
            <p>Explore integration with other SAP modules like MM, SD, and HR.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as SAP S/4HANA Finance and FICO customization.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
          <h3>Module 1: Introduction to SAP FICO</h3>
          <p>Overview of SAP FICO and its integration with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
          <h3>Module 2: Financial Accounting</h3>
          <p>Understanding financial accounting processes in SAP, including general ledger, AR, AP, and asset accounting.</p>
          </div>
          <div className="syllabus-topic">
          <h3>Module 3: Controlling</h3>
          <p>Managing internal cost reporting, cost centers, profit centers, and overhead management.</p>
          </div>
          <div className="syllabus-topic">
          <h3>Module 4: Financial Reporting</h3>
          <p>Creating and analyzing financial reports and dashboards in SAP FICO.</p>
          </div>
          <div className="syllabus-topic">
          <h3>Module 5: Integration with SAP Modules</h3>
          <p>Integrating SAP FICO with modules like MM, SD, HR, and others.</p>
          </div>
          <div className="syllabus-topic">
          <h3>Module 6: Advanced Topics</h3>
          <p>Exploring advanced topics such as SAP S/4HANA Finance, migration strategies, and custom developments.</p>
          </div>
        </div>
      </section>

{/* why learn section */}
      <section className="why-learn-sapmm">
      <h2>Why You Should Learn SAP FICO</h2>
      <div className="why-learn-sapmm-items">
    <div className="why-learn-sapmm-item">
      <h3>Lucrative Career Opportunities</h3>
      <p>SAP FICO skills are in high demand across industries, leading to excellent job opportunities and career growth in finance and controlling.</p>
      </div>
    <div className="why-learn-sapmm-item">
      <h3>Industry Relevance</h3>
      <p>SAP FICO is a core module for financial management used globally, making it an essential skill for finance professionals.</p>
      </div>
    <div className="why-learn-sapmm-item">
      <h3>Versatility and Customization</h3>
      <p>SAP FICO offers extensive customization, allowing professionals to tailor the system to various business needs, from financial accounting to controlling.</p>
      </div>
    <div className="why-learn-sapmm-item">
      <h3>Continuous Learning and Development</h3>
      <p>With regular updates and certifications, SAP FICO provides continuous learning opportunities to keep up with industry trends.</p>
      </div>
  </div>
</section>
 
{/* certification section */}
<section className="certification-section">
  <div className="certification-content">
    <div className="certification-text">
      <h2>SAP FICO Certification</h2>
      <p>
      Our SAP FICO Certification in Nagpur validates your expertise in financial accounting and controlling. The certification is highly regarded and enhances your competitiveness in the job market.
      </p>
      <p>
      We prepare you comprehensively for global SAP FICO certifications, ensuring a high success rate in exams like SAP Certified Application Associate - Financial Accounting with SAP S/4HANA.
      </p>
    </div>
    <div className="certification-image">
      <img src={CertificateImage} alt="SAP MM Certification" className="certification-img" />
    </div>
  </div>
</section>


{/* key-insights section */}

  <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
                <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
                <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
                <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
                <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
                <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>


{/* alumni section */}
      <section className="alumni-section">
  <h2>Our Alumni Works At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Works At" />
  </div>
</section>

<MySlideshow/>

 {/* FAQ Section */}
 <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP FICO?</div>
            <div className="faq-answer">SAP FICO (Financial Accounting and Controlling) is a core module in SAP that helps organizations manage their financial transactions and controlling processes effectively.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">What are the prerequisites for learning SAP FICO?</div>
            <div className="faq-answer">Basic knowledge of accounting principles and financial concepts is beneficial. However, our training is designed for both beginners and professionals.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">How long is the SAP FICO course?</div>
            <div className="faq-answer">The course duration is typically 60+ hours, including theoretical and practical sessions, spread over a few weeks or months depending on the schedule.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">What is the job placement assistance provided?</div>
            <div className="faq-answer">We offer dedicated job placement assistance, including resume building, interview preparation, and job referrals to help you secure a position in SAP FICO.</div>
          </div>
        </div>
      </section>

       
  


    </div>
  );
};

export default Sapmm;
