import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/ScrollingList.css'; // Ensure this path is correct

const sapModules = [
  'SAP MM', 'SAP FICO', 'SAP ABAP', 'SAP HCM', 'SAP SCM', 
  'SAP ARIBA', 'SAP PP', 'SAP SD', 'SAP BASIS', 'SAP Fiori', 
  'SAP QM', 'SAP WM & EWM', 'SAP PM', 'SAP LE & SL',
  'SAP MM', 'SAP FICO', 'SAP ABAP', 'SAP HCM', 'SAP SCM', 
  'SAP ARIBA', 'SAP PP', 'SAP SD', 'SAP BASIS', 'SAP Fiori', 
  'SAP QM', 'SAP WM & EWM', 'SAP PM', 'SAP LE & SL'
];

const ScrollingList = () => {
  useEffect(() => {
    AOS.init({ duration: 1200,
      mirror: true,
      once:false,
     });
  }, []);

  return (
    <div className="scrolling-list-container">
      <div className="scrolling-list">
        {sapModules.map((module, index) => (
          <div key={index} className="scrolling-item" >
            {module}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingList;
