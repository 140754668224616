import React, { useEffect, useState, useRef  } from 'react';
import '../Css/Counter.css'; // Make sure to create this CSS file
const CounterComponent = () => {
  const [counters, setCounters] = useState({
    placementAssistance: 0,
    ourStudents: 0,
    mentorSupport: 0,
    batchesCompleted: 0,
  });

  const [startCounting, setStartCounting] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCounting(true);
            observer.unobserve(entry.target); // Stop observing once the component is visible
          }
        });
      },
      { threshold: 0.1 } // Start the animation when 10% of the component is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (startCounting) {
      const interval = setInterval(() => {
        setCounters((prevCounters) => ({
          placementAssistance: Math.min(
            prevCounters.placementAssistance + 1,
            100
          ),
          ourStudents: Math.min(prevCounters.ourStudents + 150, 13000), // Example value
          mentorSupport: Math.min(prevCounters.mentorSupport + 1, 100), // Example value
          batchesCompleted: Math.min(prevCounters.batchesCompleted + 1, 176), // Example value
        }));
      }, 50);

      return () => clearInterval(interval);
    }
  }, [startCounting]);

  return (
    <div className="counter-component" ref={counterRef}>
      <div className="background-image">
        <div className="content-overlay">
          <h1 className="title">SAP Training Tech. in Nagpur</h1>
          <p className="description">
            Experience expert-led training, hands-on skills, and comprehensive
            curriculum with 100% placement assistance. Start your successful SAP career today!
          </p>
        </div>
      </div>
      <div className="counter-row">
          <div className="counter">
            <span>{counters.placementAssistance}%</span>
            <p>Placement Assistance</p>
          </div>
          <div className="counter">
            <span>{counters.ourStudents}+</span>
            <p>Our Students</p>
          </div>
          <div className="counter">
            <span>{counters.mentorSupport}%</span>
            <p>Mentor Support</p>
          </div>
          <div className="counter">
            <span>{counters.batchesCompleted}+</span>
            <p>Batches Completed</p>
          </div>
        </div>
    </div>
  );
};


export default CounterComponent;
