import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP SD Classes in Nagpur</h2>
        <p>
          Join SAP SD classes in Nagpur for the best SAP SD training, designed for both freshers and experienced professionals. With expert guidance and guaranteed placement assistance, our course covers all technical topics in detail, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
          Our job-oriented program not only awards you a certificate but also imparts knowledge equivalent to over 10 years of field experience. We respect your time and strive to complete the SAP SD training in just 1.5 months. Enroll with us to advance your career with practical skills and valuable certification.
        </p>
        <h3>Every topic will be thoroughly explored, including:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP SD software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP SD professional do?</h2>
        <ul className="role-list">
          <li>Configure and customize SAP SD modules to meet business requirements.</li>
          <li>Manage sales processes, order processing, and distribution effectively.</li>  
          <li>Handle customer data management and ensure data accuracy.</li>
          <li>Create reports and dashboards for sales insights and decision-making.</li>
          <li>Collaborate with stakeholders, provide training and support, and optimize SAP SD for business success.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP SD</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP SD</h3>
            <p>Understand the basics of SAP SD and its role in the SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP SD Configuration</h3>
            <p>Learn to configure SAP SD modules to meet business requirements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Sales Order Management</h3>
            <p>Master sales order processing, customer management, and order fulfillment.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Data Management</h3>
            <p>Handle data management, quality, and reporting within SAP SD.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Automation in SAP SD</h3>
            <p>Explore automation options and streamline sales processes in SAP SD.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as integration with other SAP modules and custom developments.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP SD</h3>
            <p>Overview of SAP SD, its modules, and how it integrates with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: SAP SD Master Data</h3>
            <p>Understanding master data in SAP SD, including customer master and material master.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Sales Order Management</h3>
            <p>Detailed exploration of the sales order process, including order creation, processing, and delivery.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Inventory Management</h3>
            <p>Managing inventory in SAP SD, including stock levels, goods receipts, and stock transfers.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Billing and Invoicing</h3>
            <p>Process of billing, invoicing, and integration with the financial module.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Reporting and Analytics</h3>
            <p>Creating reports and analyzing data within SAP SD to support business decisions.</p>
          </div>
        </div>
      </section>

      {/* Why Learn Section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP SD</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP SD skills are highly sought after, with a growing demand for professionals proficient in managing sales and distribution processes. This translates into excellent job opportunities, competitive salaries, and significant career advancement prospects in various industries.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP SD is a core module in SAP's ERP suite, widely used by organizations globally to streamline their sales, distribution, and order management processes. Mastering SAP SD ensures you’re equipped with a skill set relevant to numerous industries, including manufacturing, retail, and logistics.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP SD offers extensive customization options to tailor the system to specific business needs. This versatility allows professionals to adapt SAP SD solutions for diverse roles, from sales specialists to distribution managers, enhancing its applicability across different business functions.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>Learning SAP SD provides access to continuous professional development through certifications, training programs, and a supportive global community. The platform’s regular updates and advancements offer ongoing opportunities to expand your knowledge and stay current with industry trends and best practices.</p>
          </div>
        </div>
      </section>
 
      {/* Certification Section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP SD Certification</h2>
            <p>
              Certification from SAP SD Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP SD. 
            </p>
            <p>
              The SAP SD certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP SD certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - Sales and Distribution with SAP S/4HANA.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP SD Certification" className="certification-img" />
          </div>
        </div>
      </section>
{/* Key Insights Section */}
<section className="key-insights-section">
  <div className="key-insights-content">
    <div className="key-insights-text">
      <h2>Key Insights</h2>
      <div className="key-insights-list">
        <ul>
          <li><strong>Key Highlights:</strong></li>
          <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
          <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
          <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
          <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
          <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
          <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
          <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
          <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
          <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
          <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
        </ul>
      </div>
    </div>
  </div>
</section>

{/* Alumni Section */}
<section className="alumni-section">
  <h2>Our Alumni Work At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Work At" />
  </div>
</section>

<MySlideshow/>

{/* FAQ Section */}
<section className="faq-section">
  <h2>Frequently Asked Questions</h2>
  <div className="faq">
    <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
      <div className="faq-question">What is SAP SD?</div>
      <div className="faq-answer">SAP SD (Sales and Distribution) is a module in SAP ERP Central Component (ECC) that handles sales and distribution processes including order management, shipping, and invoicing.</div>
    </div>
    <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
      <div className="faq-question">What are the prerequisites for learning SAP SD?</div>
      <div className="faq-answer">Basic knowledge of business processes and familiarity with SAP's general concepts can be helpful, but there are no strict prerequisites for learning SAP SD.</div>
    </div>
    <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
      <div className="faq-question">What career opportunities are available after completing SAP SD training?</div>
      <div className="faq-answer">Career opportunities include roles like SAP SD Consultant, SAP SD Analyst, SAP SD End-User, and various sales and distribution management positions.</div>
    </div>
    <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
      <div className="faq-question">How does SAP SD integrate with other SAP modules?</div>
      <div className="faq-answer">SAP SD integrates with modules like SAP MM (Materials Management), SAP PP (Production Planning), SAP QM (Quality Management), and SAP FICO (Finance and Controlling) to streamline various business processes.</div>
    </div>
  </div>
</section>

      
    </div>
  );
}

export default Sapmm;
