import React from 'react';
import '../Css/Sapcourse.css';
import ECO from '../assets/ECO.webp'
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { Link } from 'react-router-dom';

const Sapcourse = () => {
  const [text] = useTypewriter({
    words: ['Why Learn SAP?', 'Where to Learn?', 'Scope of Learning SAP?', 'Why Learn SAP from SAP Training Tech?', ''],
    loop: {},
  });

  const List = [
    "Industrial experts as a Trainer of SAP",
    "80% Practical based Training of SAP and 20% Theory",
    "Printed notes",
    "Course duration 4 Months",
    "Classes will be on hour",
    "4 to 5 Dummy Projects",
    "SAP Server access (SAP Software for training)",
    "Certification(Institute and Global certification",
    "Internship letter of 6 months",
    "ECC6.0+HANA version training",
    "Small batch size",
    "Morning and Evening batches Availabel",
    "Mock Interview Practice",
    "Resume Building Session",
    "100% Placement Assistance",
    "Email-Etiquette, Group discussion,Public Speaking,Communication Skill,Personal Interview,Technical Rounds,HR Grooming",
  ];

  const half = Math.ceil(List.length / 2);
  const firstHalf = List.slice(0, half);
  const secondHalf = List.slice(half);

  return (
    <div className='sap-course'>
      <div className='banner-image'>
        <div className='left-info'>
          <h1>SAP Classes in Nagpur</h1>
          <p>SAP Training Tech. offers expert-led SAP classes in Nagpur, designed to equip professionals with in-depth knowledge of SAP modules, practical training, and hands-on experience to advance their careers in the tech industry.</p>
          <button><a href="https://docs.google.com/forms/d/e/1FAIpQLScoNqvBk1W2gCJ9Mp88fsrtihcNuCTyTmId60jIYq75UsvZWQ/viewform?vc=0&c=0&w=1&flr=0">Book Demo</a></button>
        </div>
      </div>
      <div className='sapcourse-animate'>
        <div className='circle-animate'>
          <div className='base-circle'></div>
          <div className='animate-border'>
            <img src={ECO} alt="" />
            <div className='rotate-center'>
              <span className='circle1'></span>
            </div>
          </div>
        </div>
        <div className='animate-content'>
          <h1>{text}  <Cursor /></h1>
          <div className="list-container">
            <div className="column">
              {firstHalf.map((item, index) => (
                <div key={index} className="list-item">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
                    alt="Green check"
                    className="check-icon"
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className="column">
              {secondHalf.map((item, index) => (
                <div key={index} className="list-item">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
                    alt="Green check"
                    className="check-icon"
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='benefits-section'>
        <p className='benefits-text'>
          Discover the benefits of learning <span>SAP:</span> enhance your career prospects, gain industry-recognized certifications, and become proficient in one of the world's leading enterprise solutions.
        </p>
        <div className='right-info'>
          <div className='inside-info'>
            <img src="https://1000logos.net/wp-content/uploads/2023/07/SAP-Logo.png" alt="" />
            <p>SAP (Systems, Applications, and Products) is a leading enterprise resource planning (ERP) software used by businesses worldwide to manage operations, finance, supply chain, and customer relations. </p>
          </div>
        </div>
      </div>
      {/* New SAP Modules Section */}
      <div className='sap-modules'>
        <h2>Explore SAP Modules</h2>
        <p>Each SAP module offers specialized functionalities, providing a wide scope for various industries. Here are some popular SAP modules:</p>
        <div className='modules-grid'>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/warehouse.png" alt="SAP MM" />
            <h3>SAP MM (Materials Management)</h3>
            <p>Streamline procurement, inventory, and material management in your organization.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/accounting.png" alt="SAP FICO" />
            <h3>SAP FICO (Financial Accounting & Controlling)</h3>
            <p>Manage financial transactions, accounting, and controlling efficiently.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/development-skill.png" alt="SAP ABAP" />
            <h3>SAP ABAP (Advanced Business Application Programming)</h3>
            <p>Customize SAP software solutions using ABAP programming language.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/teamwork.png" alt="SAP HCM" />
            <h3>SAP HCM (Human Capital Management)</h3>
            <p>Manage employee data, payroll, and HR processes with ease.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/delivery.png" alt="SAP SCM" />
            <h3>SAP SCM (Supply Chain Management)</h3>
            <p>Optimize supply chain operations across various business networks.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/cloud.png" alt="SAP Ariba" />
            <h3>SAP Ariba</h3>
            <p>Streamline procurement and supplier collaboration in the cloud.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/manufacturing.png" alt="SAP PP" />
            <h3>SAP PP (Production Planning)</h3>
            <p>Optimize manufacturing processes, from planning to execution.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/sales-performance.png" alt="SAP SD" />
            <h3>SAP SD (Sales & Distribution)</h3>
            <p>Manage sales orders, shipping, and billing processes efficiently.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/96/000000/external-database-web-hosting-flaticons-lineal-color-flat-icons.png" alt="SAP Basis" />
            <h3>SAP Basis</h3>
            <p>Oversee the technical foundation and administration of SAP systems.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/design.png" alt="SAP Fiori" />
            <h3>SAP Fiori</h3>
            <p>Improve user experience with modern and intuitive SAP applications.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/inspection.png" alt="SAP QM" />
            <h3>SAP QM (Quality Management)</h3>
            <p>Ensure product quality through inspections and process controls.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/warehouse.png" alt="SAP WM & EWM" />
            <h3>SAP WM & EWM (Warehouse Management)</h3>
            <p>Manage warehouse operations, inventory, and logistics effectively.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/color/96/000000/maintenance.png" alt="SAP PM" />
            <h3>SAP PM (Plant Maintenance)</h3>
            <p>Handle equipment maintenance and service management.</p>
          </div>
          <div className='module'>
            <img src="https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/96/000000/external-logistics-logistics-kmg-design-detailed-outline-kmg-design.png" alt="SAP LE & SL" />
            <h3>SAP LE & SL (Logistics Execution & Service Logistics)</h3>
            <p>Optimize logistics execution and service delivery processes.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Sapcourse;
