import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp';
import CertificateImage from '../../assets/certificate.webp';
import MySlideshow from '../../components/MySlideshow';

const Sapwmewm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (
    <div className="sap-mm-container">
      {/* Introduction Section */}
      <section className="content-section">
        <h2>Advance Your Career with SAP EW & EWM Classes in Nagpur</h2>
        <p>
          Unlock your potential with our premier SAP EW & EWM classes in Nagpur. Designed for both newcomers and seasoned professionals, our training program offers in-depth knowledge and practical experience in SAP EW & EWM. Gain the expertise needed to excel in your career with expert guidance and job placement assistance.
        </p>
        <p>
          Our intensive program, completed in just 1.5 months, provides you with a comprehensive understanding equivalent to over a decade of experience. Enroll now to elevate your career with cutting-edge skills and a valuable certification.
        </p>
        <h3>What We Offer:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Tailored resume-building workshops to enhance your profile</li>
          <li><FaClipboardCheck className="benefit-icon" /> Thorough interview preparation to boost your confidence</li>
          <li><FaLaptopCode className="benefit-icon" /> Hands-on internship experience spanning six months</li>
          <li><FaBriefcase className="benefit-icon" /> Job placement guarantee to kickstart your career</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP EW & EWM software for practical learning</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience to solidify your skills</li>
        </ul>
      </section>

      {/* Role Description Section */}
      <section className="role-description">
        <h2>Role of an SAP EW & EWM Professional</h2>
        <ul className="role-list">
          <li>Configure and customize SAP EW & EWM modules to align with business needs.</li>
          <li>Efficiently manage inventory and procurement processes.</li>
          <li>Ensure data accuracy and handle data management tasks.</li>
          <li>Create insightful reports and dashboards to aid decision-making.</li>
          <li>Collaborate with stakeholders, provide training, and optimize SAP EW & EWM systems for success.</li>
        </ul>
      </section>

      {/* Roadmap Section */}
      <section className="roadmap-section">
        <h2>Your Path to Mastering SAP EW & EWM</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP EW & EWM</h3>
            <p>Grasp the fundamentals of SAP EW & EWM and its significance within the SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP EW & EWM Configuration</h3>
            <p>Learn to configure SAP EW & EWM modules to meet specific business needs.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Inventory and Procurement</h3>
            <p>Acquire mastery over inventory management and procurement processes, including vendor management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Data Management</h3>
            <p>Manage data quality, reporting, and analytics within SAP EW & EWM.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Automation in SAP EW & EWM</h3>
            <p>Explore automation options to streamline business processes.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced concepts, including integration with other SAP modules and custom developments.</p>
          </div>
        </div>
      </section>

      {/* Syllabus Section */}
      <section className="syllabus-section">
        <h2>Course Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP EW & EWM</h3>
            <p>An overview of SAP EW & EWM, its modules, and its integration with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: Master Data Management</h3>
            <p>Understanding master data within SAP EW & EWM, including material and vendor masters.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Procurement Process</h3>
            <p>In-depth exploration of procurement processes, including purchase requisitions, purchase orders, and vendor management.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Inventory Management</h3>
            <p>Managing inventory including goods receipts, goods issues, and stock transfers.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Invoice Verification</h3>
            <p>Understanding invoice verification and its integration with the financial module.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Reporting and Analytics</h3>
            <p>Creating and analyzing reports within SAP EW & EWM to support business decisions.</p>
          </div>
        </div>
      </section>
{/* why learn section */}
<section className="why-learn-sapmm">
  <h2>Why You Should Learn SAP WM & EWM</h2>
  <div className="why-learn-sapmm-items">
    <div className="why-learn-sapmm-item">
      <h3>Lucrative Career Opportunities</h3>
      <p>SAP WM (Warehouse Management) and SAP EWM (Extended Warehouse Management) skills are highly sought after, with a growing demand for professionals proficient in managing warehouse processes. This translates into excellent job opportunities, competitive salaries, and significant career advancement prospects in various industries.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Industry Relevance</h3>
      <p>SAP WM and SAP EWM are critical modules in SAP's ERP suite, widely used by organizations globally to streamline their warehouse and inventory management processes. Mastering SAP WM and EWM ensures you’re equipped with a skill set relevant to numerous industries, including manufacturing, retail, and logistics.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Versatility and Customization</h3>
      <p>SAP WM and EWM offer extensive customization options to tailor the system to specific business needs. This versatility allows professionals to adapt SAP solutions for diverse roles, from warehouse managers to logistics coordinators, enhancing their applicability across different business functions.</p>
    </div>
    <div className="why-learn-sapmm-item">
      <h3>Continuous Learning and Development</h3>
      <p>Learning SAP WM and EWM provides access to continuous professional development through certifications, training programs, and a supportive global community. The platform’s regular updates and advancements offer ongoing opportunities to expand your knowledge and stay current with industry trends and best practices.</p>
    </div>
  </div>
</section>

{/* certification section */}
<section className="certification-section">
  <div className="certification-content">
    <div className="certification-text">
      <h2>SAP WM & EWM Certification</h2>
      <p>
        Certification from SAP WM & EWM Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP WM and EWM.
      </p>
      <p>
        The SAP WM and EWM certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP WM and EWM certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - Warehouse Management with SAP S/4HANA.
      </p>
    </div>
    <div className="certification-image">
      <img src={CertificateImage} alt="SAP WM & EWM Certification" className="certification-img" />
    </div>
  </div>
</section>

{/* key-insights section */}
<section className="key-insights-section">
  <div className="key-insights-content">
    <div className="key-insights-text">
      <h2>Key Insights</h2>
      <div className="key-insights-list">
        <ul>
          <li><strong>Key Highlights:</strong></li>
          <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
          <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
          <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
          <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
          <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
          <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
          <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
          <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
          <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
          <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
          <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
        </ul>
      </div>
    </div>
  </div>
</section>


{/* alumni section */}
<section className="alumni-section">
  <h2>Our Alumni Works At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Works At" />
  </div>
</section>

<MySlideshow/>

{/* FAQ Section */}
<section className="faq-section">
  <h2>Frequently Asked Questions</h2>
  <div className="faq">
    <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
      <div className="faq-question">What is SAP WM?</div>
      <div className="faq-answer">SAP WM (Warehouse Management) is a module in SAP ERP Central Component (ECC) that provides companies with advanced warehouse management capabilities.</div>
    </div>
    <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
      <div className="faq-question">What is SAP EWM?</div>
      <div className="faq-answer">SAP EWM (Extended Warehouse Management) is an advanced module that offers enhanced warehouse management functionalities and integration with other SAP modules.</div>
    </div>
    <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
      <div className="faq-question">What career opportunities are available after completing SAP WM & EWM training?</div>
      <div className="faq-answer">Career opportunities include roles like SAP WM Consultant, SAP EWM Consultant, SAP WM Analyst, SAP EWM Analyst, and various warehouse management positions.</div>
    </div>
    <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
      <div className="faq-question">How does SAP WM & EWM integrate with other SAP modules?</div>
      <div className="faq-answer">SAP WM and EWM integrate with modules like SAP SD (Sales and Distribution), SAP PP (Production Planning), SAP QM (Quality Management), and SAP FICO (Finance and Controlling) to streamline warehouse and supply chain processes.</div>
    </div>
  </div>
</section>


      {/* Key Insights Section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h3>Key Highlights:</h3>
            <ul className="key-insights-list">
              <li><FaCheckCircle className="benefit-icon" /> Extensive Training: Over 53 hours of instructor-led sessions and 40+ hours of hands-on practice.</li>
              <li><FaCheckCircle className="benefit-icon" /> Real-world Projects: Work on live projects to gain practical experience.</li>
              <li><FaCheckCircle className="benefit-icon" /> Expert Instructors: Learn from seasoned professionals with real-world experience.</li>
              <li><FaCheckCircle className="benefit-icon" /> Personalized Support: Access to mentoring, career counseling, and interview preparation.</li>
              <li><FaCheckCircle className="benefit-icon" /> Certification Preparation: Comprehensive guidance for passing SAP EW & EWM certification exams.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* alumni section */}
      <section className="alumni-section">
        <h2>Our Alumni Works At</h2>
        <div className="alumni-image">
          <img src={PlacementImage} alt="Our Alumni Works At" />
        </div>
      </section>

      <MySlideshow />

      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP EW & EWM?</div>
            <div className="faq-answer">
              SAP EW (Extended Warehouse) and EWM (Extended Warehouse Management) are advanced modules in SAP that manage warehouse operations. SAP EWM provides comprehensive and flexible solutions for managing high-volume, complex warehouse operations, while SAP EW focuses on integrating warehouse processes with broader supply chain management.
            </div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">What are the prerequisites for learning SAP EW & EWM?</div>
            <div className="faq-answer">
              Basic knowledge of warehouse management and logistics concepts is beneficial. Familiarity with SAP’s general modules can also be helpful, but there are no strict prerequisites for learning SAP EW & EWM.
            </div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">What career opportunities are available after completing SAP EW & EWM training?</div>
            <div className="faq-answer">
              Completing SAP EW & EWM training opens up career opportunities such as SAP EWM Consultant, Warehouse Manager, Supply Chain Analyst, and Logistics Coordinator. These roles involve optimizing warehouse processes, managing inventory, and improving supply chain efficiency.
            </div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">How does SAP EW & EWM integrate with other SAP modules?</div>
            <div className="faq-answer">
              SAP EW & EWM integrates with modules like SAP SD (Sales and Distribution), SAP MM (Materials Management), SAP PP (Production Planning), and SAP FICO (Finance and Controlling) to streamline warehouse operations, inventory management, and overall supply chain processes.
            </div>
          </div>
        </div>
      </section>



    </div>
  );
};

export default Sapwmewm;
