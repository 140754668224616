import React, { useState, useEffect } from 'react';
import '../Css/Footer.css'; // Importing CSS for styling
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Importing social media icons

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;

      // Check if the user has scrolled to the bottom
      if (scrollY + windowHeight >= documentHeight - 1) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    
      {/* Fixed Footer */}

      <footer id='footer'>
        <div className="motive-color"></div> {/* Adding the motive color element */}
        <div className="footer-content fixed-footer-content">
          <div className="footer-section info">
            <h2>Head Office</h2>
            <p> 606 B-wing, Lokmat Bhavan, Lokmat Square, Ramdaspeth, Nagpur, Maharashtra 440012</p>
            <p>Contact no.: <a href="tel:08010924303">08010924303</a></p>
            <p>Email: <a href="mailto:info@saptrainingtech.com">info@saptrainingtech.com</a></p>
          </div>
        </div>
        <h1>SAP Training Tech.</h1>
        <div className="footer-bottom-wrapper">
          <hr />
          <div className="footer-bottom-text">
              &copy; 2024 SAP Classes Nagpur. All Rights Reserved.
            </div>
        </div>
      </footer>
      <div className="footer-bottom">
            <div className="social-icons">
              <a href="https://www.facebook.com/Sapalogy/" aria-label="Facebook"><FaFacebook /></a>
              <a href="https://x.com/Sapalogy_ltd" aria-label="Twitter"><FaTwitter /></a>
              <a href="https://www.linkedin.com/company/sapalogy-pvt-ltd?originalSubdomain=in" aria-label="LinkedIn"><FaLinkedin /></a>
              <a href="https://www.instagram.com/sapalogy_pvt.ltd/?hl=en" aria-label="Instagram"><FaInstagram /></a>
            </div>
           
          </div>
    </>
  );
};

export default Footer;
