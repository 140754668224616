import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP Fiori Classes in Nagpur</h2>
        <p>
          Join SAP Fiori classes in Nagpur for the best SAP Fiori training, designed for both freshers and experienced professionals. With expert guidance and guaranteed placement assistance, our course covers all technical topics in detail, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
          Our job-oriented program not only awards you a certificate but also imparts knowledge equivalent to over 10 years of field experience. We respect your time and strive to complete the SAP Fiori training in just 1.5 months. Enroll with us to advance your career with practical skills and valuable certification.
        </p>
        <h3>Every topic will be thoroughly explored, including:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP Fiori software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP Fiori professional do?</h2>
        <ul className="role-list">
          <li>Design and implement SAP Fiori applications to enhance user experience.</li>
          <li>Customize and configure SAP Fiori apps according to business needs.</li>  
          <li>Integrate SAP Fiori with SAP S/4HANA and other SAP modules.</li>
          <li>Develop and deploy Fiori applications using SAPUI5 and OData services.</li>
          <li>Collaborate with stakeholders to ensure Fiori applications meet business requirements and optimize user workflows.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP Fiori</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP Fiori</h3>
            <p>Understand the basics of SAP Fiori and its role in the SAP ecosystem.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP Fiori Configuration</h3>
            <p>Learn to configure SAP Fiori apps to meet business requirements.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>UI5 and OData Services</h3>
            <p>Master the development of Fiori applications using SAPUI5 and OData services.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Integration with SAP S/4HANA</h3>
            <p>Handle integration of Fiori apps with SAP S/4HANA.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Custom Fiori Development</h3>
            <p>Explore custom development options for SAP Fiori applications.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as performance optimization and deployment strategies for Fiori apps.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP Fiori</h3>
            <p>Overview of SAP Fiori, its architecture, and integration with SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: SAPUI5 Development</h3>
            <p>Understanding SAPUI5 framework for developing Fiori applications.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: OData Services</h3>
            <p>Working with OData services for data retrieval and manipulation in Fiori apps.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Fiori App Configuration</h3>
            <p>Configuring and customizing Fiori applications to meet business requirements.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Integration with SAP S/4HANA</h3>
            <p>Integration techniques for connecting Fiori apps with SAP S/4HANA.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Reporting and Analytics</h3>
            <p>Creating reports and analyzing data within Fiori applications to support business decisions.</p>
          </div>
        </div>
      </section>

      {/* why learn section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP Fiori</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP Fiori skills are highly sought after, with a growing demand for professionals proficient in designing and implementing user-friendly applications. This translates into excellent job opportunities, competitive salaries, and significant career advancement prospects in various industries.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP Fiori is a modern and essential module for enhancing user experience within SAP's ecosystem. Mastering SAP Fiori ensures you’re equipped with a skill set relevant to numerous industries, including manufacturing, retail, and logistics.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP Fiori offers extensive customization options to tailor the system to specific business needs. This versatility allows professionals to adapt Fiori solutions for diverse roles, from UX designers to application developers, enhancing its applicability across different business functions.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>Learning SAP Fiori provides access to continuous professional development through certifications, training programs, and a supportive global community. The platform’s regular updates and advancements offer ongoing opportunities to expand your knowledge and stay current with industry trends and best practices.</p>
          </div>
        </div>
      </section>

      {/* certification section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP Fiori Certification</h2>
            <p>
              Certification from SAP Fiori Classes in Nagpur by our institute validates your course completion and demonstrates your expertise in SAP Fiori.
            </p>
            <p>
              The SAP Fiori certification enhances your job market competitiveness by equipping you with essential, industry-relevant skills. Our training institute provides comprehensive preparation for global SAP Fiori certifications, ensuring a high success rate in exams such as SAP Certified Application Associate - SAP Fiori Implementation and Configuration.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP Fiori Certification" className="certification-img" />
          </div>
        </div>
      </section>
{/* Key Insights Section */}
<section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
                <li><FaCheckCircle className="benefit-icon" /> Training Focused on Job-Ready Skills and Industry Relevance</li>
                <li><FaCheckCircle className="benefit-icon" /> Extensive Library of 69 Hours of On-Demand Video Content</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Support Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Opportunity for a Free Demo Class</li>
                <li><FaCheckCircle className="benefit-icon" /> Competitive and Transparent Fee Structure</li>
                <li><FaCheckCircle className="benefit-icon" /> Flexible Learning Schedule Options</li>
                <li><FaCheckCircle className="benefit-icon" /> Successfully Concluded 912+ Training Batches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Alumni Section */}
      <section className="alumni-section">
        <h2>Our Alumni Works At</h2>
        <div className="alumni-image">
          <img src={PlacementImage} alt="Our Alumni Works At" />
        </div>
      </section>

      {/* Slideshow */}
      <MySlideshow />

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP Fiori?</div>
            <div className="faq-answer">SAP Fiori is a user experience (UX) design approach by SAP that provides a modern and consistent user interface for SAP applications across all devices.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">What are the prerequisites for learning SAP Fiori?</div>
            <div className="faq-answer">Basic knowledge of SAP and familiarity with web technologies like HTML, CSS, and JavaScript can be helpful, but there are no strict prerequisites for learning SAP Fiori.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">What career opportunities are available after completing SAP Fiori training?</div>
            <div className="faq-answer">Career opportunities include roles like SAP Fiori Developer, SAP UI5 Developer, SAP Fiori Consultant, and various positions in UX design and development within SAP environments.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">How does SAP Fiori integrate with other SAP modules?</div>
            <div className="faq-answer">SAP Fiori integrates with modules like SAP S/4HANA, SAP CRM, SAP SRM, and others to provide a unified user experience across different SAP applications and processes.</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sapmm;
