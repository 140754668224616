import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Css/WhyChooseUs.css'; // Ensure this path is correct
import sapImage from '../assets/Know.webp'; // Ensure you have an image path

const WhyChooseUs = () => {
  return (
    <div className="choose-us-section">
      <div className="choose-us-heading" >
        <h1>Why Choose Us?</h1>
        {/* <p className='choose-us-card2'>Choose us for industry-leading expertise, hands-on training, and guaranteed career growth in SAP!</p> */}
      </div>
      <div className="choose-us-cards">
        <div className="choose-us-card"  > 
          <h3>Expert Instructors</h3>
          <p>Learn from experienced industry professionals who bring real-world insights and best practices to the classroom, ensuring you receive top-notch education.</p>
        </div>
        <div className="choose-us-card" >
          <h3>Hands-On Training</h3>
          <p>Gain practical, hands-on experience through projects, simulations, and labs that simulate real-world DevOps scenarios, allowing you to apply your skills directly.</p>
        </div>
        <div className="choose-us-card" >
          <h3>Comprehensive Curriculum</h3>
          <p>Our curriculum covers the latest tools, technologies, and methodologies in DevOps, ensuring you’re equipped with the most relevant skills demanded by employers.</p>
        </div>
        <div className="choose-us-card" >
          <h3>Placement Assistance</h3>
          <p>Benefit from our dedicated placement assistance program, helping you secure job opportunities with top companies and kick-start your career in DevOps with confidence.</p>
        </div>
      </div>
    </div>
  );
};

const SAPDidYouKnow = () => {
  const facts = [
    "SAP is one of the world's leading producers of software for the management of business processes.",
    "Over 77% of all global transactions touch an SAP system.",
    "SAP was founded in 1972 in Germany by five former IBM engineers.",
    "SAP's ERP software is used by 92% of the Forbes Global 2000 companies.",
    "The SAP HANA database allows businesses to process transactions and analytics in real time.",
  ];

  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClick = (index) => {
    setClickedIndex(index);
    setTimeout(() => setClickedIndex(null), 500); // Reset after animation
  };

  return (
    <div className="sap-did-you-know-container">
      <img src={sapImage} alt="SAP" className="sap-image" />
      <div className="sap-facts-container" >
        <ul className="sap-fact-list">
          {facts.map((fact, index) => (
            <li
              key={index}
              className={`sap-fact-item ${clickedIndex === index ? 'clicked' : ''}`}
              onClick={() => handleClick(index)}
            >
              <p className="sap-fact-text">{fact}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const CombinedSections = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="fixed-background">
      <WhyChooseUs />
      <SAPDidYouKnow />
    </div>
  );
};

export default CombinedSections;
