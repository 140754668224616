import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import rev1 from "../assets/rev1.webp";
import rev2 from "../assets/rev2.webp";
import rev3 from "../assets/rev3.webp";
import rev4 from "../assets/rev4.webp";
import rev5 from "../assets/rev5.webp";
import '../Css/MySwiper.css'

const MySlideshow = () => {
  return (
    <div>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        pagination={{ clickable: true }}
        loop={true}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,  // Show 1 slide on small screens
            spaceBetween: 10
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,  // Show 2 slides on medium screens
            spaceBetween: 20
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,  // Show 3 slides on large screens
            spaceBetween: 30
          },
        }}
      >
        <SwiperSlide>
          <img src={rev1} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={rev2} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={rev3} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={rev4} alt="Slide 4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={rev5} alt="Slide 5" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MySlideshow;
