

import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP BASIS Classes in Nagpur</h2>
        <p>
        Enroll in our SAP BASIS classes in Nagpur, designed to provide you with expert training in SAP BASIS administration and management. Whether you're a fresher or an experienced professional, our course offers in-depth coverage of all technical aspects, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
        With a job-oriented program, certification, and practical knowledge equivalent to years of experience, our SAP BASIS training is tailored to help you achieve your career goals efficiently.
        </p>
        <h3>Our comprehensive training includes:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP BASIS software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP BASIS professional do?</h2>
        <ul className="role-list">
          <li>Administer and manage SAP BASIS environments and landscapes.</li>
          <li>Handle system installations, configurations, and upgrades.</li>  
          <li>Manage user roles, authorizations, and system security.</li>
          <li>Monitor system performance and troubleshoot technical issues.</li>
          <li>Collaborate with other IT teams to ensure smooth SAP operations and support.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP BASIS</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP BASIS</h3>
            <p>Understand the basics of SAP BASIS and its role in system administration.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP BASIS Configuration</h3>
            <p>Learn to configure SAP BASIS environments to meet business needs.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>System Administration</h3>
            <p>Master system administration tasks including user management, security, and monitoring.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Performance Monitoring</h3>
            <p>Manage system performance, troubleshoot issues, and ensure optimal operation.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Integration with Other Modules</h3>
            <p>Explore integration with other SAP modules and IT systems.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as SAP HANA and BASIS optimization techniques.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP BASIS</h3>
            <p>Overview of SAP BASIS and its integration with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: System Administration</h3>
            <p>Understanding system administration processes, including installations, configurations, and security.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Performance Monitoring</h3>
            <p>Managing system performance, troubleshooting issues, and optimizing operations.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: User and Authorization Management</h3>
            <p>Creating and managing user roles, authorizations, and system security.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Integration with SAP Modules</h3>
            <p>Integrating SAP BASIS with other modules and IT systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Advanced Topics</h3>
            <p>Exploring advanced topics such as SAP HANA, optimization, and future trends in BASIS administration.</p>
          </div>
        </div>
      </section>

      {/* why learn section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP BASIS</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP BASIS skills are in high demand across industries, leading to excellent job opportunities and career growth in system administration and IT management.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP BASIS is a core module for system administration used globally, making it an essential skill for IT professionals.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP BASIS offers extensive customization, allowing professionals to tailor system administration to various business needs and technical environments.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>With regular updates and certifications, SAP BASIS provides continuous learning opportunities to keep up with industry trends and technological advancements.</p>
          </div>
        </div>
      </section>
 
      {/* certification section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP BASIS Certification</h2>
            <p>
              Our SAP BASIS Certification in Nagpur validates your expertise in SAP system administration. The certification is highly regarded and enhances your competitiveness in the job market.
            </p>
            <p>
              We prepare you comprehensively for global SAP BASIS certifications, ensuring a high success rate in exams like SAP Certified Technology Associate - System Administration with SAP HANA.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP BASIS Certification" className="certification-img" />
          </div>
        </div>
      </section>

      {/* key-insights section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achievements</li>
                <li><FaCheckCircle className="benefit-icon" /> Dedicated Mentorship Throughout the Training Program</li>
                <li><FaCheckCircle className="benefit-icon" /> Professional Resume Enhancement Services</li>
                <li><FaCheckCircle className="benefit-icon" /> Practical Project Work and Exercises Spanning 52 Hours</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Assistance with Leading Companies</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* alumni section */}
<section className="alumni-section">
<h2>Our Alumni Works At</h2>
<div className="alumni-image">
  <img src={PlacementImage} alt="Our Alumni Works At" />
</div>
</section>
<MySlideshow/>

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">  What is SAP BASIS and why is it important?</div>
            <div className="faq-answer">SAP BASIS is a technical module that focuses on the administration of SAP environments. It is crucial for ensuring the smooth operation of SAP systems, including their installation, configuration, and performance optimization.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">  What are the prerequisites for the SAP BASIS course?</div>
            <div className="faq-answer">Basic knowledge of IT and experience with databases and system administration is beneficial. No prior SAP knowledge is required, but familiarity with SAP concepts can be an advantage.</div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question"> How long is the SAP BASIS training program?</div>
            <div className="faq-answer">The training program typically spans several weeks, depending on the schedule and format you choose. It includes comprehensive classroom or online sessions, practical exercises, and exam preparation.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">What career opportunities are available after completing the SAP BASIS course?</div>
            <div className="faq-answer">Upon completion, you can pursue roles such as SAP BASIS Administrator, SAP System Analyst, or SAP Support Consultant. Opportunities exist in various industries including finance, manufacturing, and technology.</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sapmm;

