import React from 'react'
import HeroComponent from '../components/Hero'
import ImageOverlayCards from '../components/OverlayCard'
import ScrollingList from '../components/ScrollingList'
import CombinedSections from '../components/WhyChooseUs'
import SplashScreen from '../components/SplashScreen'
import Counter from '../components/Counter'
import '../index.css'

const Home = () => {
  return (
    <div className='home'>
      <SplashScreen />
      <HeroComponent />
      <ScrollingList />
     
      <CombinedSections />
      <ImageOverlayCards />
      <Counter />
    </div>
  )
}

export default Home
