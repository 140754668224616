import React from 'react';
import '../Css/Contact.css';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaClock } from 'react-icons/fa'; // FontAwesome icons
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const data = new FormData(form);

    data.append('access_key', 'b9bdd60b-160e-4cb3-8d4a-7cc822c28e91'); // Replace with your actual access key
    
    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: data,
    });

    const result = await response.json();

    if (result.success) {
      toast.success('Message sent successfully!');
      form.reset();
    } else {
      toast.error('Something went wrong. Please try again.');
    }
  };

  return (
    <div className='contact'>
      <div className="contact-page">
        <div className="contact-content">
          <div className="contact-text">
            <h1>Get in Touch with Us!</h1>
            <p>
              Have questions or need assistance? Our team is here to help you
              with all your inquiries and support needs.
            </p>
          </div>
        </div>
        <div className="main-card">
          <div className="contact-details">
            <div className="contact-item">
              <div className="icon-container">
                <FaEnvelope className="contact-icon" />
              </div>
              <h3>Email Us</h3>
              <p>info@saptrainingtech.com</p>
            </div>
            <div className="contact-item">
              <div className="icon-container">
                <FaPhone className="contact-icon" />
              </div>
              <h3>Call Us</h3>
              <p>+91 9890005315</p>
            </div>
            <div className="contact-item">
              <div className="icon-container">
                <FaClock className="contact-icon" />
              </div>
              <h3>Opening Hours</h3>
              <p>Mon - Sat: 10 AM - 7 PM</p>
            </div>
            <div className="contact-item">
              <div className="icon-container">
                <FaMapMarkerAlt className="contact-icon" />
              </div>
              <h3>Our Office</h3>
              <p>606 B-wing Lokmat Bhavan, Nagpur</p>
            </div>
          </div>
        </div>
      </div>

      <div className="form-section">
        <h2>Reach Out to Us!</h2>
        <p>We’re Here to Help, Anytime</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input type="hidden" name="access_key" value="YOUR_ACCESS_KEY_HERE" />
          <div className="form-group">
            <label htmlFor="first-name">First Name:</label>
            <input type="text" id="first-name" name="first-name" required />
          </div>
          <div className="form-group">
            <label htmlFor="last-name">Last Name:</label>
            <input type="text" id="last-name" name="last-name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Contact;
