import React, { useState } from 'react';
import { FaGraduationCap, FaBriefcase, FaLaptopCode, FaFileAlt, FaClipboardCheck, FaBuilding, FaRoad, FaCheckCircle } from 'react-icons/fa';
import '../../Css/Sapmm.css'; // Import the CSS file
import PlacementImage from '../../assets/placement.webp'; 
import CertificateImage from '../../assets/certificate.webp'; 
import MySlideshow from '../../components/MySlideshow';

const Sapmm = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (  
    <div className="sap-mm-container">
      <section className="content-section">
        <h2>Join Our SAP LE & SL Classes in Nagpur</h2>
        <p>
        Enroll in our SAP LE (Logistics Execution) and SAP SL (Supply Chain Logistics) classes in Nagpur, designed to provide you with expert training in logistics and supply chain management. Whether you're a fresher or an experienced professional, our course offers in-depth coverage of all technical aspects, ensuring you gain real-time, hands-on experience.
        </p>
        <p>
        With a job-oriented program, certification, and practical knowledge equivalent to years of experience, our SAP LE & SL training is tailored to help you achieve your career goals efficiently.
        </p>
        <h3>Our comprehensive training includes:</h3>
        <ul className="benefits-list">
          <li><FaFileAlt className="benefit-icon" /> Comprehensive resume-building workshops</li>
          <li><FaClipboardCheck className="benefit-icon" /> Intensive interview preparation sessions</li>
          <li><FaLaptopCode className="benefit-icon" /> Six-month hands-on internship</li>
          <li><FaBriefcase className="benefit-icon" /> Guaranteed job placement program</li>
          <li><FaBuilding className="benefit-icon" /> Access to SAP LE & SL software for all students</li>
          <li><FaGraduationCap className="benefit-icon" /> Real-time project experience</li>
        </ul>
      </section>

      <section className="role-description">
        <h2>What does an SAP LE & SL professional do?</h2>
        <ul className="role-list">
          <li>Administer and manage SAP LE & SL environments and processes.</li>
          <li>Handle logistics operations, supply chain management, and inventory control.</li>  
          <li>Manage order fulfillment, distribution, and warehouse operations.</li>
          <li>Monitor supply chain performance and troubleshoot logistics issues.</li>
          <li>Collaborate with other departments to ensure efficient logistics operations.</li>
        </ul>
      </section>

      <section className="roadmap-section">
        <h2>Roadmap to Master SAP LE & SL</h2>
        <div className="roadmap-cards">
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Introduction to SAP LE & SL</h3>
            <p>Understand the basics of SAP LE and SL and their role in logistics and supply chain management.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>SAP LE & SL Configuration</h3>
            <p>Learn to configure SAP LE and SL environments to meet business needs.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Logistics Administration</h3>
            <p>Master logistics administration tasks including order management, distribution, and warehouse operations.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Performance Monitoring</h3>
            <p>Manage supply chain performance, troubleshoot issues, and ensure optimal logistics operations.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Integration with Other Modules</h3>
            <p>Explore integration with other SAP modules and external IT systems.</p>
          </div>
          <div className="roadmap-card">
            <FaRoad className="roadmap-icon" />
            <h3>Advanced Topics</h3>
            <p>Dive into advanced topics such as SAP S/4HANA and LE/SL optimization techniques.</p>
          </div>
        </div>
      </section>

      {/* New Syllabus Section */}
      <section className="syllabus-section">
        <h2>Syllabus</h2>
        <div className="syllabus-content">
          <div className="syllabus-topic">
            <h3>Module 1: Introduction to SAP LE & SL</h3>
            <p>Overview of SAP LE and SL and their integration with other SAP systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 2: Logistics Administration</h3>
            <p>Understanding logistics administration processes, including order management, distribution, and warehouse operations.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 3: Performance Monitoring</h3>
            <p>Managing supply chain performance, troubleshooting issues, and optimizing operations.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 4: Order and Inventory Management</h3>
            <p>Creating and managing order fulfillment processes, inventory control, and distribution.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 5: Integration with SAP Modules</h3>
            <p>Integrating SAP LE and SL with other modules and IT systems.</p>
          </div>
          <div className="syllabus-topic">
            <h3>Module 6: Advanced Topics</h3>
            <p>Exploring advanced topics such as SAP S/4HANA, optimization, and future trends in logistics management.</p>
          </div>
        </div>
      </section>

      {/* why learn section */}
      <section className="why-learn-sapmm">
        <h2>Why You Should Learn SAP LE & SL</h2>
        <div className="why-learn-sapmm-items">
          <div className="why-learn-sapmm-item">
            <h3>Lucrative Career Opportunities</h3>
            <p>SAP LE & SL skills are in high demand across industries, leading to excellent job opportunities and career growth in logistics and supply chain management.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Industry Relevance</h3>
            <p>SAP LE & SL are core modules for logistics and supply chain management used globally, making them essential skills for IT and logistics professionals.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Versatility and Customization</h3>
            <p>SAP LE & SL offer extensive customization, allowing professionals to tailor logistics management to various business needs and technical environments.</p>
          </div>
          <div className="why-learn-sapmm-item">
            <h3>Continuous Learning and Development</h3>
            <p>With regular updates and certifications, SAP LE & SL provide continuous learning opportunities to keep up with industry trends and technological advancements.</p>
          </div>
        </div>
      </section>

      {/* certification section */}
      <section className="certification-section">
        <div className="certification-content">
          <div className="certification-text">
            <h2>SAP LE & SL Certification</h2>
            <p>
              Our SAP LE & SL Certification in Nagpur validates your expertise in logistics and supply chain management. The certification is highly regarded and enhances your competitiveness in the job market.
            </p>
            <p>
              We prepare you comprehensively for global SAP LE & SL certifications, ensuring a high success rate in exams like SAP Certified Application Associate - Logistics Execution and Supply Chain Management.
            </p>
          </div>
          <div className="certification-image">
            <img src={CertificateImage} alt="SAP LE & SL Certification" className="certification-img" />
          </div>
        </div>
      </section>

      {/* key-insights section */}
      <section className="key-insights-section">
        <div className="key-insights-content">
          <div className="key-insights-text">
            <h2>Key Insights</h2>
            <div className="key-insights-list">
              <ul>
                <li><strong>Key Highlights:</strong></li>
                <li><FaCheckCircle className="benefit-icon" /> Comprehensive Training with Over 53+ Hours of Instructor-Led Sessions</li>
                <li><FaCheckCircle className="benefit-icon" /> Assistance with Certification Achieving SAP LE & SL Exam Success</li>
                <li><FaCheckCircle className="benefit-icon" /> Access to Practice Labs and Real-World Scenarios</li>
                <li><FaCheckCircle className="benefit-icon" /> Job Placement Assistance and Resume Building</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

     {/* alumni section */}
     <section className="alumni-section">
  <h2>Our Alumni Works At</h2>
  <div className="alumni-image">
    <img src={PlacementImage} alt="Our Alumni Works At" />
  </div>
</section>

<MySlideshow/>

      {/* FAQ Section */}
 <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <div className={`faq-item ${faqOpen === 0 ? 'open' : ''}`} onClick={() => toggleFaq(0)}>
            <div className="faq-question">What is SAP LE & SL?</div>
            <div className="faq-answer"> SAP LE (Logistics Execution) is designed for managing the execution of logistics processes within SAP. SAP SL (Supply Chain Logistics) encompasses broader supply chain management, focusing on optimizing the flow of goods and services.</div>
          </div>
          <div className={`faq-item ${faqOpen === 1 ? 'open' : ''}`} onClick={() => toggleFaq(1)}>
            <div className="faq-question">Who should take this course?</div>
            <div className="faq-answer">  This course is ideal for IT professionals, logistics managers, supply chain professionals, and anyone interested in advancing their career in logistics and supply chain management.
            </div>
          </div>
          <div className={`faq-item ${faqOpen === 2 ? 'open' : ''}`} onClick={() => toggleFaq(2)}>
            <div className="faq-question">What are the prerequisites for this course?</div>
            <div className="faq-answer"> Basic knowledge of logistics and supply chain concepts is recommended but not mandatory. Familiarity with SAP or ERP systems is beneficial.</div>
          </div>
          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">What will I receive upon completion?</div>
            <div className="faq-answer"> Upon completion, you will receive a certification of completion and gain practical knowledge and skills in SAP LE & SL, making you job-ready for roles in logistics and supply chain management.</div>
          </div>

          <div className={`faq-item ${faqOpen === 3 ? 'open' : ''}`} onClick={() => toggleFaq(3)}>
            <div className="faq-question">Are there job placement opportunities?</div>
            <div className="faq-answer">   Yes, we offer job placement assistance and guidance throughout the recruitment process to help you secure a position in the field of logistics and supply chain management.</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sapmm;
